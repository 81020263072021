import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import "../styles/general-styles.css"
import SunLogo from "../images/sunxtreme-favicon.png"

export default function PrivacyPolicy() {
  return (
    <div className="page-background">
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="description" content="Sunxtreme privacy policy." />
          <meta name="author" content="Sean Rooney" />
          <link rel="icon" href={SunLogo} type="image/gif" sizes="16x16"></link>
          <link rel="canonical" href="https://sunxtreme.com/privacy-policy" />
          <title>
            Privacy Policy -SunXtreme Solar Pool Heating Systems | Solar Pool
            Panels
          </title>
        </Helmet>
        <Container className="container-background">
          <br/>
          <h1 >Privacy Policy</h1>
          <br />
          <p>
            This privacy policy sets out how SunXtreme uses and protects any
            information that you give SunXtreme when you use this website.
            SunXtreme is committed to ensuring that your privacy is protected.
            Should we ask you to provide certain information by which you can be
            identified when using this website, then you can be assured that it
            will only be used in accordance with this privacy statement.
            SunXtreme may change this policy from time to time by updating this
            page. You should check this page from time to time to ensure that
            you are happy with any changes. This policy is effective from 1st of
            January, 2015.
          </p>
          <br />
          <h4>What we collect:</h4>
          <br />
          <h6>We may collect the following information:</h6>
          <br />
          <div>• name</div>
          <div>• contact information including email address</div>
          <div>• demographic information such as postcode</div>
          <div>• demographic information such as postcode</div>
          <br />
          <h4>What we do with the information we gather:</h4>
          <br />
          <h6>
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </h6>
          <br />
          <div>• Internal record keeping.</div>
          <div>
            • We may use the information to improve our products and services.
          </div>
          <div>
            • We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </div>
          <div>
            • From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail.
          </div>
          <div>
            • We may use the information to customize the website according to
            your interests.
          </div>
          <br />
          <h4>Security:</h4>
          <br />
          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </p>
          <br />
          <h4>How we use cookies:</h4>
          <br />
          <p>
            A cookie is a small file which asks permission to be placed on your
            computer’s hard drive. Once you agree, the file is added and the
            cookie helps analyze web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences. We use traffic log cookies to identify which
            pages are being used. This helps us analyze data about webpage
            traffic and improve our website in order to tailor it to customer
            needs. We only use this information for statistical analysis
            purposes and then the data is removed from the system. Overall,
            cookies help us provide you with a better website, by enabling us to
            monitor which pages you find useful and which you do not. A cookie
            in no way gives us access to your computer or any information about
            you, other than the data you choose to share with us. You can choose
            to accept or decline cookies. Most web browsers automatically accept
            cookies, but you can usually modify your browser setting to decline
            cookies if you prefer. This may prevent you from taking full
            advantage of the website.
          </p>
          <br />
          <h4>Links to other websites:</h4>
          <br />
          <p>
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </p>
          <br />
          <h4>Controlling your personal information:</h4>
          <br />
          <h6>
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </h6>
          <br />
          <p>
            • if you have previously agreed to us using your personal
            information for direct marketing purposes, you may change your mind
            at any time by writing to us.
          </p>
          <br />
          <p>
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about third parties which we think you may
            find interesting if you tell us that you wish this to happen. You
            may request details of personal information which we hold about you
            under the Data Protection Act 1998. A small fee will be payable. If
            you would like a copy of the information held on you please contact
            us. If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the below address. We will promptly correct any
            information found to be incorrect.
          </p>
          <br />
          <div>SunXtreme</div>
          <div>Heliocol Green Energy, Inc.</div>
          <div>2305 W. Huntington Dr.</div>
          <div>Tempe, AZ 85282</div>
          <div>800-366-6862</div>
          <div>info@heliocolwest.com</div>
          <br />
        </Container>
      </Layout>
    </div>
  )
}
